import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const RouterActions = createActionGroup({
  source: 'Router',
  events: {
    // Core Urls
    'Navigate to Login': emptyProps(),
    'Navigate to Signup': emptyProps(),
    'Navigate to Not Found': emptyProps(),
    // Project Manager Urls
    'Navigate to Project Manager': emptyProps(),
    'Navigate to Corpora': emptyProps(),
    'Navigate to Corpus Item': props<{ corpusId: number }>(),
    'Navigate to Public Corpora': emptyProps(),
    'Navigate to Label Sets': emptyProps(),
    'Navigate to Label Set Item': props<{ labelSetId: number }>(),
    'Navigate to Public Label Sets': emptyProps(),
    'Navigate to Tasks': emptyProps(),
    'Navigate to Task Item': props<{ taskId: number }>(),
    'Navigate to Task Label': props<{ taskId: number }>(),
    'Navigate to Task Statistics': props<{ taskId: number }>(),
    'Navigate to Task Texts': props<{ taskId: number }>(),
    'Navigate to User Profile': props<{ username: string }>(),
    'Navigate to User Settings': emptyProps(),
    'Navigate to Search': emptyProps(),
    // Span Task
    'Navigate to Span Task Item': props<{ taskId: number; textId: number }>(),
    // No op
    'No Op': emptyProps()
  }
});
